<style lang="less">
  .van-icon-search{
    color: #FF976A
  }
  .van-search__content{
    background: #fff;
  }
  .search .van-tabs__wrap{
      height: 2rem;
  }
</style>
<template>
    <div class="gray-bg search" v-htmltit data-title="搜索">
        <go-cart-btn></go-cart-btn>
        <go-back-btn></go-back-btn>
        <div>
            <van-search
            v-model="searchVal"
            left-icon=""
            shape="round"
            autofocus
            background="#F7F7F7"
            :placeholder="searchPlaceholder"
            @search="handleSearch" />
        </div>
        <div>
            <van-tabs v-model="tabActive" sticky color="#FF976A" title-active-color="#FF976A">
                <van-tab title-style="line-height:2rem" title="商品"></van-tab>
                <van-tab title-style="line-height:2rem" title="品牌"></van-tab>
                <van-tab title-style="line-height:2rem" title="规格"></van-tab>
            </van-tabs>
        </div>
        <div class="history-box">
            <div class="history-tit">
                <div>搜索历史</div>
                <van-icon style="color:#3F4562" name="delete" />
            </div>
            <div class="history-list">
                <div class="history-item" 
                     v-for="item in historyList" 
                     :key="item.id"
                     @click="handleClickHistroy(item.name)">{{item.name}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import './index.less'
import goCartBtn from "@/view/Public/goCartBtn"
import goBackBtn from "@/view/Public/goBackBtn"
export default {
    name:"my-search",
    components:{ goCartBtn,goBackBtn},
    data(){
        return{
            searchVal:"",
            searchPlaceholder:"请输入商品名称或关键字",
            tabActive:0,
            historyList:[
                { id:1,name:"TR" },
                { id:2,name:"CBRQMI"},
            ]
        }
    },
    watch:{
        tabActive(v){
            if(v === 0){
                this.historyList = [
                    { id:1,name:"TR" },
                    { id:2,name:"CBRQMI"},
                ]
                this.searchPlaceholder = "请输入商品名称或关键字"
            }else if(v === 1){
                this.historyList = [
                    { id:1,name:"明月" },
                    { id:2,name:"依视路"},
                ]
                this.searchPlaceholder = "请输入品牌"
            }else if(v === 2){
                this.historyList = [
                    { id:1,name:"1.56" },
                    { id:2,name:"红色"},
                    { id:3,name:"合金"},
                ]
                this.searchPlaceholder = "请输入规格"
            }
        }
    },
    methods:{
        handleSearch(val){
            this.$router.push({name:"search-list",params:{ search:val }})
        },
        handleClickHistroy(val){
            this.handleSearch(val)
        }
    }
}
</script>